import React from "react"
import Stuff from "components/stuff"
import Header from "components/Header"
import Footer from "components/Footer"
import { Link, graphql } from "gatsby"
import Sticky from "react-stickynode"
import KarateFont from "../fonts/fonts"

const ContentPage = ({data}) => {
  return (
    <div>
      <KarateFont/>
      <Sticky innerZ={1}>
        <Header showCartIcon={false} />
      </Sticky>
      <Stuff data={data.allMarkdownRemark}/>
      <Footer />
    </div>
  )
}

export default ContentPage

export const data = graphql`
  query contentQuery {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "post"}}}) {
    edges {
      node {
        id
        frontmatter {
          slug
          image {
            id
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          type
        }
      }
    }
  }
  }
`
