import React from "react"
import Img from "gatsby-image"

const Stuff = ({ data }) => {
  return (
    <div class="container" style={{ paddingBottom: "60px" }}>
      <div class="row">
        {data.edges.map(({ node }) => (
          <div class="col-md-4">
            <Img
              fluid={node.frontmatter.image.childImageSharp.fluid}
              style={{
                maxHeight: "450px",
                maxWidth: "350px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <div
              class="d-flex justify-content-center"
              style={{ marginTop: "8px", marginBottom: "20px" }}
            >
              <a href={node.frontmatter.image.publicURL} download>
                Download
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stuff
